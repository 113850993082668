<template>
  <div class="row">
    <div class="contents">
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-Vertical card-default card-md mb-4">
            <div class="card-header">
              <h6>Simula tu campaña</h6>
            </div>
            <div class="card-body py-md-30">
              <form>
                <div class="row">
                  <div class="col-md-6 mb-25">
                    <div class="form-group form-element-textarea">
                      <label for="exampleFormControlTextarea1" class="il-gray fs-14 fw-500 align-center mb-10">Prompt
                        General</label>
                      <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" maxlength="500"
                        v-model="pgeneral"></textarea>
                    </div>
                  </div>
                  <div class="col-md-6 mb-25">
                    <div class="form-group form-element-textarea">
                      <label for="exampleFormControlTextarea2" class="il-gray fs-14 fw-500 align-center mb-10">Prompt
                        Especifico</label>
                      <textarea class="form-control" id="exampleFormControlTextarea2" rows="3" maxlength="500"
                        v-model="pespecifico"></textarea>
                    </div>
                  </div>
                  <div class="col-md-6 mb-25">
                    <div class="form-group form-element-textarea">
                      <label for="exampleFormControlTextarea3" class="il-gray fs-14 fw-500 align-center mb-10">Prompt
                        Productos</label>
                      <textarea class="form-control" id="exampleFormControlTextarea3" rows="3"
                        v-model="pproducto"></textarea>
                    </div>
                  </div>
                  <div class="col-md-6 mb-25">
                    <div class="form-group form-element-textarea">
                      <label for="exampleFormControlTextarea4" class="il-gray fs-14 fw-500 align-center mb-10">Prompt
                        Requisitos</label>
                      <textarea class="form-control" id="exampleFormControlTextarea4" rows="3" maxlength="500"
                        v-model="prequisito"></textarea>
                    </div>
                  </div>
                  <div class="col-md-6 mb-25">
                    <div class="form-group form-element-textarea">
                      <label for="exampleFormControlTextarea5" class="il-gray fs-14 fw-500 align-center mb-10">Prompt
                        Precios</label>
                      <textarea class="form-control" id="exampleFormControlTextarea5" rows="3"
                        v-model="pprecio"></textarea>
                    </div>
                  </div>
                  <div class="col-md-6 mb-25">
                    <div class="form-group form-element-textarea">
                      <label for="exampleFormControlTextarea6" class="il-gray fs-14 fw-500 align-center mb-10">Prompt
                        System</label>
                      <textarea class="form-control" id="exampleFormControlTextarea6" rows="3" maxlength="500"
                        v-model="psystem"></textarea>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="layout-button mt-0">
                      <button type="button" class="btn btn-default btn-squared btn-light px-20 "
                        @click="promptExample">Ejemplo</button>
                      <button type="button" class="btn btn-default btn-squared btn-light px-20 "
                        @click="borrarPrompt">Borrar todo</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- ends: .card -->
        </div>
        <div class="chat-area d-flex mb-40">
          <div class="mb-lg-0 mb-40 chat-sidebar">
            <div class="sidebar-group left-sidebar chat_sidebar">
              <div id="chat" class="left-sidebar-wrap radius-xl active">
                <div class="card card-Vertical card-default card-md mb-4">
                  <div class="card-header">
                    <h6>Estado de la conversación</h6>
                  </div>
                  <div class="card-body">
                    <div class="Vertical-form">
                      <form action="#">
                        <div class="form-group">
                          <label for="formGroupExampleInput"
                            class="color-dark fs-14 fw-500 align-center mb-10">Categoria</label>
                          <input type="text" class="form-control ih-medium ip-gray radius-xs b-light px-15"
                            id="formGroupExampleInput" :placeholder="categoria" disabled>
                        </div>
                        <div class="form-group">
                          <label for="formGroupExampleInput2"
                            class="color-dark fs-14 fw-500 align-center mb-10">Emoción</label>
                          <input type="text" class="form-control ih-medium ip-gray radius-xs b-light px-15"
                            id="formGroupExampleInput2" :placeholder="emocion" disabled>
                        </div>
                        <div class="form-group">
                          <label for="formGroupExampleInput3"
                            class="color-dark fs-14 fw-500 align-center mb-10">Status</label>
                          <input type="password" class="form-control ih-medium ip-gray radius-xs b-light px-15"
                            id="formGroupExampleInput3" :placeholder="status" disabled>
                        </div>
                        <div class="layout-button mt-25">
                          <button type="button" class="btn btn-primary btn-default btn-squared px-30"
                            @click="reiniciarConversacion">Reiniciar conversación</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <!-- ends: .card -->
              </div>
            </div><!-- Ends: .sidebar group -->
          </div><!-- Ends: .mb-lg-0 -->
          <div class="tab-content col-sm-8" id="ueberTabB">
            <div class="tab-pane fade  show active" id="panel_b_first" role="tabpanel" aria-labelledby="first-tab">
              <div class="chat">
                <div class="chat-body bg-white radius-xl">
                  <div class="chat-header">
                    <div class="media chat-name align-items-center">
                      <div class="media-body align-self-center ">
                        <h5 class=" mb-0 fw-500 mb-2">Virtualia</h5>
                        <div class="media-body__content d-flex align-items-center">
                          <span class="badge-dot dot-success me-1"></span>
                          <small class="d-flex color-light fs-12 text-capitalize">
                            activo ahora
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="conversacion.length == 0" class="card card-default card-md mb-4">
                    <div class="card-header">
                      <h6>No hay mensajes</h6>
                    </div>
                    <div class="card-body">
                      <div class="dm-empty text-center">
                        <div class="dm-empty__image">

                          <img src="/img/svg/1.png" alt="Admin Empty">
                        </div>
                        <div class="dm-empty__text">
                          <p class="">No Data</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- ends: .card -->
                  <div v-else ref="chatBox" class="chat-box chat-box--big p-xl-30 ps-lg-20 pe-lg-0">
                    <div v-for="(mensaje, index) in conversacion" :key="index">
                      <div v-if="mensaje.who === 'IA'" class="flex-1 incoming-chat">
                        <div class="chat-text-box ">
                          <div class="media d-flex">
                            <div class="chat-text-box__photo ">
                              <img src="/img/loginicon.png" class="align-self-start me-15 wh-46" alt="img">
                            </div>
                            <div class="media-body">
                              <div class="chat-text-box__content">
                                <div class="chat-text-box__title d-flex align-items-center">
                                  <h6 class="fs-14">Virtualia</h6>
                                  <span class="chat-text-box__time fs-12 color-light fw-400 ms-15">{{
                                    mensaje.fecha }}</span>
                                </div>
                                <div class="d-flex align-items-center mb-20 mt-10">
                                  <div class="chat-text-box__subtitle p-20 bg-primary">
                                    <p class="color-white">{{ mensaje.msg }}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else class="flex-1 justify-content-end d-flex outgoing-chat">
                        <div class="chat-text-box">
                          <div class="media ">
                            <div class="media-body">
                              <div class="chat-text-box__content">
                                <div class="chat-text-box__title d-flex align-items-center justify-content-end mb-2">
                                  <span class="chat-text-box__time fs-12 color-light fw-400">{{ mensaje.fecha
                                  }}</span>
                                </div>
                                <div class="d-flex align-items-center justify-content-end">
                                  <div class="chat-text-box__subtitle p-20 bg-deep">
                                    <p class="color-gray">{{ mensaje.msg }}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="chat-footer px-xl-30 px-lg-30 pb-lg-30 pt-1">
                    <div class="chat-type-text">
                      <div class="pt-0 outline-0 pb-0 pe-0 ps-0 rounded-0 position-relative d-flex align-items-center"
                        tabindex="-1">
                        <div class="d-flex justify-content-between align-items-center w-100 flex-wrap">
                          <div class=" flex-1 d-flex align-items-center chat-type-text__write ms-0">
                            <input :disabled="isDisabled" class="form-control border-0 bg-transparent box-shadow-none"
                              v-model="nMensaje" placeholder="Escriba su mensaje...">
                          </div>
                          <div class="chat-type-text__btn">
                            <button type="button" class="border-0 btn-primary wh-50 p-10 rounded-circle"
                              @click="enviarMensaje">
                              <img class="svg" src="/img/svg/send.svg" alt="send"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div><!-- ends: .chat-->
            </div>
          </div><!-- Ends: .tab-content -->
        </div><!-- Ends: .chat-area -->
      </div>
      <!-- ends: .row -->
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Playground',
  data() {
    return {
      promptEjemplo: {
        cpromptge: "Entre los tags <cultura WOM> se incluyen ciertos lineamientos sobre cómo un asistente debe comunicarse con un usuario:<culturaWOM> Nos esforzamos por darlo todo. Traspasando límites para satisfacer a nuestros clientes.Nos atrevemos a marcar la diferencia y definir nuevos estándares.Desafiamos lo establecido en pro de una mejor experiencia 	del cliente. Vivimos desde la verdad y la transparencia.</culturaWOM>",
        cpromptsp: "El usuario te contactó a través de redes sociales porque vio una publicación de una oferta que le llamó la atención y está interesado en plan telefonía móvil (portabilidad numérica).Dentro de los tags <planes> se encuentra la información de los nuevos planes de telefonía móvil disponibles y ofertas disponibles:",
        cpromptpr: "<PLANES>Nombre | valor por 1 línea | valor por 2-3 líneas | valor por 4 líneas | apps libres de roaming100 GB | 9.990 | 9.990 | 9.990 | ninguna250 GB | 12.990 | 12.990 | 12.990 | whatsapp400 GB | 15.990 | 7.490 | 6.990 | whatsapp, maps, uber, gmail, bookingLIBRE 600 GB | 19.990 | 8.490 | 7.990 | whatsapp, maps, uber, gmail, booking LIBRE PLUS 600 GB | 25.990 | 10.490 | 9.990 | whatsapp, maps, uber, gmail, booking</PLANES>",
        cpromptre: "",
        cpromptpv: "",
        cpromptsys: "Quiero que actúes como una asistente de ventas de una empresa de telefonía Chilena llamada WOM. Solamente estás interesada en tus funciones y no en otros temas no relacionados, en el caso que te pregunten por algo externo debes responder que no estas capacitado. Quiero que respondas de forma amable, no muy extensa (no más de 30 palabras por pregunta), ofreciéndole alternativas de los planes para que eventualmente contrate un servicio, presentándolas de a una o dos e ir progresivamente orientando la compra. Intenta siempre incentivar a que lleve más de una línea, mostrando los descuentos por múltiples líneas de algunos planes.",
      },
      conversacion: [
      ],
      categoria: "",
      emocion: "",
      status: "",
      nMensaje: "",
      pgeneral: "",
      pespecifico: "",
      pproducto: "",
      pprecio: "",
      prequisito: "",
      psystem: "",
      isDisabled: false
    }
  },
  mounted() {
    this.loadUserInfo()
  },
  methods: {
    loadUserInfo() {
      const isValid = sessionStorage.getItem('validated');
      if (!isValid) {
        window.location.href = "/login"
      }
    },
    obtenerHoraFormato12() {
      let fecha = new Date();
      let horas = fecha.getHours();
      let minutos = fecha.getMinutes();
      let ampm = horas >= 12 ? 'pm' : 'am';

      horas = horas % 12;
      horas = horas ? horas : 12;
      minutos = minutos < 10 ? '0' + minutos : minutos;

      return `${horas}:${minutos} ${ampm}`;
    },
    enviarMensaje() {
      const mensajeEnviado = this.escapeHTML(this.nMensaje);
      const newMensaje = {
        msg: mensajeEnviado,
        fecha: this.obtenerHoraFormato12(),
        who: 'HUMAN'
      };
      this.conversacion.push(newMensaje);
      this.nMensaje = ""
      this.toggleBotom()
      this.$nextTick(this.scrollToBottom);
      this.respuestaMensaje()
    },
    isValidJSON(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    },
    async respuestaMensaje() {
      const content = this.getPromptSystem()
      const system = [{ "role": "system", "content": content }]
      const conv = this.convertToChatFormat(this.conversacion)
      const combined = [...system, ...conv]
      try {
        const respVirtualia = await this.gpt(combined);
        const respFilter = respVirtualia["content"];
        if (!this.isValidJSON(respFilter)) {
          console.log(respFilter);
          const newMensaje = {
            msg: respFilter,
            fecha: this.obtenerHoraFormato12(),
            who: 'IA'
          };
          this.conversacion.push(newMensaje);
        } else {
          let objeto;
          try {
            objeto = JSON.parse(respFilter);
          } catch (e) {
            console.error("Error al parsear el JSON:", e);
            throw e; // Si deseas que el error interrumpa la ejecución
          }

          console.log(objeto);
          const newMensaje = {
            msg: objeto.RESPUESTA,
            fecha: this.obtenerHoraFormato12(),
            who: 'IA'
          };
          this.emocion = objeto.EMOCION;
          this.categoria = objeto.CATEGORIA;
          this.status = objeto.STATUS;
          this.conversacion.push(newMensaje);
        }

        this.$nextTick(this.scrollToBottom);
        this.toggleBotom();
      } catch (error) {
        console.error("Se produjo un error:", error);
        const newMensaje = {
          msg: "Lo lamento, no entendí correctamente tu pregunta. ¿Puedes repetirla?",
          fecha: this.obtenerHoraFormato12(),
          who: 'IA'
        };
        this.conversacion.push(newMensaje);
        this.$nextTick(this.scrollToBottom);
        this.toggleBotom();
        // Aquí puedes manejar el error, mostrar un mensaje al usuario, etc.
      }

    },
    reiniciarConversacion() {
      this.conversacion = [];
      this.mensajeIA = {
        fecha: "",
        msg: ""
      };
      this.mensajeHUMAN = {
        fecha: "",
        msg: ""
      };
    },
    promptExample() {
      this.pgeneral = this.promptEjemplo.cpromptge
      this.pespecifico = this.promptEjemplo.cpromptsp
      this.pproducto = this.promptEjemplo.cpromptpr
      this.pprecio = this.promptEjemplo.cpromptpv
      this.prequisito = this.promptEjemplo.cpromptre
      this.psystem = this.promptEjemplo.cpromptsys
    },
    toggleBotom() {
      this.isDisabled = !this.isDisabled;
    },
    borrarPrompt() {
      this.pgeneral = ""
      this.pespecifico = ""
      this.pproducto = ""
      this.pprecio = ""
      this.prequisito = ""
      this.psystem = ""
    },
    getPromptSystem() {
      const text = `ERES UN ASISTENTE VIRTUAL EL CUAL DE FORMA OBLIGATORIA DEBE RESPONDER CON FORMA DE JSON!!, con las siguientes llaves: {"EMOCION":"CORRESPONDE A QUE EMOCIONES INDICA USER CON SUS PREGUNTAS PUEDE SER FELIZ, NEUTRAL, ENOJADO","RESPUESTA":"CORRESPONDE A TU RESPUESTA QUE DAS A LA PREGUNTA DEL USER", "CATEGORIA":" CORRESPONDE A LA CATEGORIA DE INTERES DEL USUARIO ESTA PUEDE SER", "STATUS":" EL STATUS SERA TRUE SI LA PERSONA TIENE UNA INTENCION SOLIDA DE COMPRAR/PAGAR etc"}. Ejemplo de respuesta en formato JSON: {"EMOCION": "POSITIVA", "RESPUESTA": "Ofrecemos planes de telefonía móvil con excelentes tarifas. ¿Estás interesado en conocer nuestros planes de datos?", "CATEGORIA": "Obtener información del plan", "STATUS": False} TODAS TUS RESPUESTAS DEBEN RESPETAR DICHO FORMATO PARA PODER RECIBIRLO. La información que usarás para responder sobre productos será la siguiente: `
      const textExtra = `${this.pgeneral} ${this.pespecifico} ${this.pproducto} ${this.pprecio} ${this.prequisito} ${this.psystem}`
      return (text + ' ' + textExtra)
    },
    escapeHTML(unsafeText) {
      return unsafeText
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
    },
    convertToChatFormat(messages) {
      return messages.map(message => {
        let role;
        if (message.who === 'HUMAN') {
          role = 'user';
        } else if (message.who === 'IA') {
          role = 'assistant';
        } else {
          throw new Error('Unknown "who" value in message:', message);
        }
        return {
          role: role,
          content: message.msg
        };
      });
    },
    async gpt(messages) {
      const openaiEndpoint = process.env.VUE_APP_API_OPENAI;
      const headers = {
        'Authorization': `Bearer ${process.env.VUE_APP_API_KEY}`,
        'Content-Type': 'application/json'
      };

      try {
        const response = await axios.post(openaiEndpoint, {
          model: "gpt-3.5-turbo",
          messages,
          temperature: 0.1
        }, { headers });
        return response.data.choices[0].message;
      } catch (error) {
        console.error(error.response?.data || error.message);
      }
    },
    scrollToBottom() {
      const chatBox = this.$refs.chatBox;
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  }
};
</script>

