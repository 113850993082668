<template>
   <main class="main-content">
      <div class="admin">
         <div class="container-fluid">
            <div class="row justify-content-center">
               <div class="col-xxl-3 col-xl-4 col-md-6 col-sm-8">
                  <div class="edit-profile">
                     <div class="edit-profile__logos">
                        <img class="dark" src="img/loginicon.png" alt="">
                     </div>
                     <div class="card border-0">
                        <div class="card-header">
                           <div class="edit-profile__title">
                              <h6>Iniciar sesión</h6>
                           </div>
                        </div>
                        <div class="card-body">
                           <div class="edit-profile__body">
                              <div class="form-group mb-25">
                                 <label for="username">Correo electrónico</label>
                                 <input v-model="loginUser.uemail" type="text" class="form-control" id="username"
                                    placeholder="nombre.apellido@email.cl">
                              </div>
                              <div class="form-group mb-15">
                                 <label for="password-field">Contraseña</label>
                                 <div class="position-relative">
                                    <input v-model="loginUser.clave" id="password-field" type="password"
                                       class="form-control" name="password" placeholder="************">
                                    <div class="uil uil-eye-slash text-lighten fs-15 field-icon toggle-password2">
                                    </div>
                                 </div>
                              </div>
                              <div
                                 class="admin__button-group button-group d-flex pt-1 justify-content-md-start justify-content-center">
                                 <a @click.prevent="iniciarSesion()"
                                    class="btn btn-primary btn-default w-100 btn-squared text-capitalize lh-normal px-50 signIn-createBtn ">
                                    Ingresar
                                 </a>
                              </div>
                           </div>
                        </div><!-- End: .card-body -->
                     </div><!-- End: .card -->
                  </div><!-- End: .edit-profile -->
               </div><!-- End: .col-xl-5 -->
            </div>
         </div>
      </div><!-- End: .admin-element  -->
   </main>
</template>
<script>
export default {
  name: 'LoginView',
  data() {
      return {
         loginUser: {
            uemail: "",
            clave: ""
         },
      }
   },
   mounted(){
      this.loadUserInfo()
   },
   methods: {
      loadUserInfo() {
         const isValid = sessionStorage.getItem('validated');
         if (isValid) {
            window.location.href = "/playground"
         }
      },
      iniciarSesion() {
         if (this.loginUser.uemail !== '' && this.loginUser !== '') {
            console.log(this.loginUser)
            if (this.loginUser.uemail === process.env.VUE_APP_USER && this.loginUser.clave === process.env.VUE_APP_PW) {
                     sessionStorage.setItem('validated', true);
                     window.location.href = "/playground"
            }
            else{
              console.log("Contraseña incorrecta")
            }
         }
         else{
          console.log("Campos vacios")
         }
      }
   },
}
</script>
